.offer-trade {
  .ant-transfer {
    display: flex;
    flex-direction: column;

    @include when-above(600px) {
      flex-direction: row;

      .ant-transfer-operation {
        transform: initial;
      }
    }

    &-operation {
      transform: rotate(90deg);
    }
  }

  &__expiry-time {
    max-width: 100%;
  }
}
