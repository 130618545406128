.trade {
  .ant-card-head-wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-basis: initial;
    }
  }

  .ant-card-extra {
    margin-left: 0;
  }
}
