.discord-link {
  border-color: #7788d6;
  background: #7788d6;
  color: $white;

  &:hover {
    border-color: rgba(#7788d6, 0.8);
    background: rgba(#7788d6, 0.8);
  }

  .anticon {
    margin: 0;
  }

  svg {
    height: 25px;
    margin: 0;
    fill: $white;
  }
}
