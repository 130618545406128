// antd styles, must match styles delcared in craco.config.js
$primary-color: #ff8a22;
$link-color: #ff8a22;
$success-color: #3292e6;
$warning-color: #decc47;
$error-color: #d65858;
$info-color: #3d4ecc;
$font-size-base: 14px;
$layout-header-background: #fff;
$layout-footer-background: #fff;
$component-background: #fff;
$white: #fff;
