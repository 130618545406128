.create-faction {
  &__discord {
    width: 80%;
    margin: 0 auto;

    &__spacer {
      @include flex-row-above(540px);
      justify-content: center;
      gap: 5px;
      width: 100%;
    }
  }
}
