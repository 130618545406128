.how-does-this-work {
  .ant-card-head-title {
    white-space: normal;
  }

  &__iframe-container {
    @media (max-width: 754px) {
      position: relative;
      height: 0;
      padding-top: 12px;
      padding-bottom: 56.25%; /* 16:9 */

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  img {
    max-width: 100%;
  }
}
