.league {
  .ant-card-head-title {
    .ant-space {
      flex-wrap: wrap;
    }
  }

  .tabbed-card {
    .ant-card-head-wrapper {
      @include flex-row-above(mobile);
    }

    .ant-card-extra {
      margin: 0 auto;

      @include when-above(mobile) {
        margin: 0 0 0 auto;
      }
    }

    .ant-card-body {
      padding-right: 10px;
      padding-left: 10px;

      @include when-above(mobile) {
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }

  &__factions-label {
    word-wrap: normal;
  }
}
