.draft-rosters {
  max-height: 75vh;
  overflow: scroll;

  .ant-card-body {
    padding: 0;
  }

  &__table {
    margin: 24px 24px 24px 0;
  }
}
