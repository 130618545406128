$header-breakpoint: 500px;

.header {
  padding: 0 10px;
  @include when-above($header-breakpoint) {
    padding: 0 50px;
  }

  .logo svg {
    max-width: 100px;

    @include when-above($header-breakpoint) {
      max-width: none;
    }
  }
}
