.discover-leagues {
  &__cta-card {
    .ant-space {
      @include flex-row-above(mobile);
      justify-content: space-between;
      gap: 5px;
      width: 100%;
    }
  }
}
