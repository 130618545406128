.faction {
  .ant-card-head-wrapper {
    @include flex-row-above(mobile);
  }

  .ant-card-head-title {
    width: 100%;

    @include when-above(mobile) {
      width: inherit;
    }
  }

  .ant-card-extra {
    margin: 0 auto 0 0;
    padding: 0 0 16px;

    @include when-above(mobile) {
      margin: 0 0 0 auto;
      padding: 16px 0;
    }
  }

  &__overview-header {
    .ant-descriptions-item {
      padding: 8px 0;
    }
  }
}
