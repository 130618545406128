@mixin when-above($points...) {
  // Not sure if theres a better way to do this without duplicating
  // the @content for each breakpoint.. good enough for now.
  @each $point in $points {
    @if $point == desktop {
      @media (min-width: 1200px) { @content ; }
    } @else if $point == laptop {
      @media (min-width: 992px) { @content ; }
    } @else if $point == small-laptop {
      @media (min-width: 925px) { @content ; }
    } @else if $point == tablet {
      @media (min-width: 769px) { @content ; }
    } @else if $point == mobile {
      @media (min-width: 425px) { @content ; }
    } @else {
      @media (min-width: $point) { @content ; }
    }
  }
}

@mixin center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin center-vertical {
  display: flex;
  align-items: center;
}

@mixin overflow-ellipsis {
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin flex-row-above($point) {
  flex-direction: column;

  @include when-above($point) {
    flex-direction: row;
  }
}
