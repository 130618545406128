.draft-rounds {
  &__round-header-container {
    padding: 13px 0;
  }

  &__pick {
    &--on-the-clock {
      animation: pulse 1s infinite;
    }

    &--unused {
      opacity: 0.5;
    }
  }
}

@keyframes pulse {
  0% {
    background-color: $component-background;
  }

  50% {
    background-color: rgba($success-color, 0.2);
  }

  100% {
    background-color: $component-background;
  }
}
