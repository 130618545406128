.draft {
  &__pick-countdown--youre-on-the-clock * {
    color: $error-color;
  }

  &__starts-at {
    .ant-statistic-title {
      text-align: center;
    }
  }

  &__drawer {
    .ant-statistic {
      margin-bottom: 10px;
    }

    .ant-table-body {
      // I'm sorry.. had to fight the inline styles set by antd here :(
      max-height: 72vh !important;
    }
  }

  .draft-players-container {
    > div {
      padding-top: 13px;
    }
  }

  .draft-rosters-container {
    > div {
      padding-top: 12px;
    }
  }
}

