.notification-bell {
  width: 16px;
  height: 18px;
  padding: 0;
  border: 0;
}

.notification-bell__menu {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;

  .ant-dropdown-menu-item {
    padding: 10px;
  }
}
