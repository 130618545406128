.page {
  min-height: 100vh;

  &__main-content {
    display: flex;
    flex-direction: column;
    margin: 24px 16px;
  }

  &__vertically-centered {
    @include center-vertical;
    flex: 1;
  }
}
