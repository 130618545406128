html,
body {
  width: 100%;
  min-width: 360px;
}

.inline-form-container {
  height: 80px;
}

.scroll-gradient {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), #fff);
  }
}

.space-wrapper {
  width: 100%;
}

.center-text {
  text-align: center;
}

.tabbed-card {
  .ant-card-body {
    padding-top: 0;
  }
}

.anticon-info-circle {
  cursor: pointer;
}
